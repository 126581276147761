.modal .modal-body {
    overflow-y: auto;
}

.modal-dialog {
    overflow-y: initial !important
}

.modal-body {
    max-height: 80vh;
    overflow-y: auto;
}