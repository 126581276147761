.inline-input {
    padding: 0 3px 0 3px;
    margin-left: 5px;
    border: 1px solid transparent;
    line-height: 20px !important;
    width: 100%;
}

.inline-text {
    padding: 0 3px 0 3px;
    border: 0;
    line-height: 20px !important;
    width: 100%;
}

.inline-checkbox {
    padding: 0 3px 0 3px;
    border: 1px solid white;
    line-height: 20px !important;
}

.inline-input:focus {
    border: 1px solid #66afe9;
    border-radius: 2px;
    outline: 0;
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102,175,233,.6);
    transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    transition-property: border-color, box-shadow;
    transition-duration: 0.15s, 0.15s;
    transition-timing-function: ease-in-out, ease-in-out;
    transition-delay: 0s, 0s;
}

.inline-input-dirty {
    color: red !important;
}
