.imgcol {
    background-color: transparent;
    display: table;
    padding: 10px;
    margin: 0 0 40px 0;
    width: 100%
}

.imgcol:hover, .imgcol:focus, .imgcol.active {
    background-color: #f5f5f5;
}

.imgcol-header {
    margin: 2px 2px 2px 2px;
    height: 35px;
}

.imgcol-header:before {
    content: " ";
    display: table;
}

.imgcol-header:after {
    content: " ";
    display: table;
    clear: both;
}

.imgcol-header h3 {
    margin: 0;
}

.imgcol-description {
    margin: 0 2px 2px 2px;
}

.imgcol-tags {
    margin: 0 2px 5px 2px;
}

.imgcol-footer {
    margin: 0 2px 0 2px;
}

.imgcol-thumbnails {
    content: " ";
    display: table;
    width: 100%;
    clear: both;
}

.imgcol-thumbnail {
    position: relative;
    float: left;
    padding: 0;
    display: block;
    border: 2px solid transparent;
    -webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
}

.imgcol-thumbnail:hover, .imgcol-thumbnail:focus, .imgcol-thumbnail.active {
    border: 2px solid #428bca;
}

.imgcol-thumbnail .imgcol-thumbnail-actions {
    display: none;
    position: absolute;
    bottom: 6px;
    right: 6px;
    padding: 3px 5px 0 4px;
    border-radius: 2px;
    background: rgba(87, 84, 83, 0.87);
}

.imgcol-thumbnail .imgcol-thumbnail-actions a {
    padding: 0 5px;
}

.imgcol-thumbnail .imgcol-thumbnail-actions .glyphicon {
    color: white;
}

.imgcol-thumbnail:hover .imgcol-thumbnail-actions {
    display: block;
}

.imgcol-thumbnail .imgcol-thumbnail-show-all {
    display: none;
    position: absolute;
    top: 5px;
    right: 5px;
    padding: 3px 5px;
    border-radius: 2px;
    background: rgba(87, 84, 83, 0.87);
    cursor: pointer;
}

.imgcol-thumbnail .imgcol-thumbnail-show-all a {
    padding: 0 5px;
}

.imgcol-thumbnail .imgcol-thumbnail-show-all span {
    color: white;
}

.imgcol-thumbnails:hover .imgcol-thumbnail-show-all {
    display: block;
}

.imgcol .imgcol-actions {
    display: none;
}

.imgcol:hover .imgcol-actions {
    display: block;
}

#clearImgColFilter {
    position: absolute;
    right: 20px;
    top: 0;
    bottom: 0;
    height: 14px;
    margin: auto;
    font-size: 14px;
    cursor: pointer;
    color: #ccc;
}