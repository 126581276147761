.navbar {
    margin: 0;
}

.navbar-fixed-top {
    border: 0;
    padding: 0 5px;
}

.navbar-right {
    margin-right: 0 !important;
}

.navbar-nav > li > .dropdown-menu {
    top: 50px;
}

.navbar-nav .dropdown-toggle {
    cursor: pointer;
}

.navbar-nav > .open a,
.navbar-nav > .open a:hover,
.navbar-nav > .open a:focus {
    background-color: #fff !important;
}

.navbar-brand {
    font-size: 24px;
}

.navbar-nav > li > a {
    padding-top: 15px;
    padding-bottom: 0;
}

.img-profile {
    height: 28px;
}

.profile-picture {
    background-color: #fff !important;
    padding: 11px 13px !important;
    cursor: pointer;
    vertical-align: middle;
    width: 62px;
}

.profile-picture > img {
    display: block;
}

.profile-picture > i {
    display: none;
    vertical-align: middle;
    text-align: center;
    padding-top: 3px;
    padding-right: 5px;
}

.profile-picture:hover img {
    display: none;
}

@-webkit-keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}
@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}

.profile-picture:hover i {
    display: block;
    -webkit-animation: fadeIn 1s;
    animation: fadeIn 1s;
}

.navbar-divider {
    width: 0;
    border-right: 1px solid #e3e6f0;
    height: 34px;
    margin: 8px 10px;
}