@font-face {
  font-family: 'Glyphicons Halflings';
  src: url('../../assets/fonts/glyphicons-halflings-regular.eot');
  src: url('../../assets/fonts/glyphicons-halflings-regular.eot?#iefix') format('embedded-opentype'), url('../../assets/fonts/glyphicons-halflings-regular.woff2') format('woff2'), url('../../assets/fonts/glyphicons-halflings-regular.woff') format('woff'), url('../../assets/fonts/glyphicons-halflings-regular.ttf') format('truetype'), url('../../assets/fonts/glyphicons-halflings-regular.svg#glyphicons_halflingsregular') format('svg');
}
.halflings {
  position: relative;
  top: 1px;
  display: inline-block;
  font-family: 'Glyphicons Halflings';
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  vertical-align: top;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.halflings.x05 {
  font-size: 6px;
}
.halflings.x2 {
  font-size: 24px;
}
.halflings.x3 {
  font-size: 36px;
}
.halflings.x4 {
  font-size: 48px;
}
.halflings.x5 {
  font-size: 60px;
}
.halflings.x6 {
  font-size: 72px;
}
.halflings.x7 {
  font-size: 84px;
}
.halflings.x8 {
  font-size: 96px;
}
.halflings.light:before {
  color: #f2f2f2;
}
.halflings.drop:before {
  text-shadow: -1px 1px 3px rgba(0, 0, 0, 0.3);
}
.halflings.flip {
  -moz-transform: scaleX(-1);
  -o-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  filter: FlipH;
  -ms-filter: "FlipH";
}
.halflings.flipv {
  -moz-transform: scaleY(-1);
  -o-transform: scaleY(-1);
  -webkit-transform: scaleY(-1);
  transform: scaleY(-1);
  filter: FlipV;
  -ms-filter: "FlipV";
}
.halflings.rotate90 {
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}
.halflings.rotate180 {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}
.halflings.rotate270 {
  -webkit-transform: rotate(270deg);
  -moz-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg);
}
.halflings-glass:before {
  content: "\E001";
}
.halflings-music:before {
  content: "\E002";
}
.halflings-search:before {
  content: "\E003";
}
.halflings-envelope:before {
  content: "\2709";
}
.halflings-heart:before {
  content: "\E005";
}
.halflings-star:before {
  content: "\E006";
}
.halflings-star-empty:before {
  content: "\E007";
}
.halflings-user:before {
  content: "\E008";
}
.halflings-film:before {
  content: "\E009";
}
.halflings-th-large:before {
  content: "\E010";
}
.halflings-th:before {
  content: "\E011";
}
.halflings-th-list:before {
  content: "\E012";
}
.halflings-ok:before {
  content: "\E013";
}
.halflings-remove:before {
  content: "\E014";
}
.halflings-zoom-in:before {
  content: "\E015";
}
.halflings-zoom-out:before {
  content: "\E016";
}
.halflings-off:before {
  content: "\E017";
}
.halflings-signal:before {
  content: "\E018";
}
.halflings-cog:before {
  content: "\E019";
}
.halflings-trash:before {
  content: "\E020";
}
.halflings-home:before {
  content: "\E021";
}
.halflings-file:before {
  content: "\E022";
}
.halflings-time:before {
  content: "\E023";
}
.halflings-road:before {
  content: "\E024";
}
.halflings-download-alt:before {
  content: "\E025";
}
.halflings-download:before {
  content: "\E026";
}
.halflings-upload:before {
  content: "\E027";
}
.halflings-inbox:before {
  content: "\E028";
}
.halflings-play-circle:before {
  content: "\E029";
}
.halflings-repeat:before {
  content: "\E030";
}
.halflings-refresh:before {
  content: "\E031";
}
.halflings-list-alt:before {
  content: "\E032";
}
.halflings-glyph-lock:before {
  content: "\E033";
}
.halflings-flag:before {
  content: "\E034";
}
.halflings-headphones:before {
  content: "\E035";
}
.halflings-volume-off:before {
  content: "\E036";
}
.halflings-volume-down:before {
  content: "\E037";
}
.halflings-volume-up:before {
  content: "\E038";
}
.halflings-qrcode:before {
  content: "\E039";
}
.halflings-barcode:before {
  content: "\E040";
}
.halflings-tag:before {
  content: "\E041";
}
.halflings-tags:before {
  content: "\E042";
}
.halflings-book:before {
  content: "\E043";
}
.halflings-glyph-bookmark:before {
  content: "\E044";
}
.halflings-print:before {
  content: "\E045";
}
.halflings-glyph-camera:before {
  content: "\E046";
}
.halflings-font:before {
  content: "\E047";
}
.halflings-bold:before {
  content: "\E048";
}
.halflings-italic:before {
  content: "\E049";
}
.halflings-text-height:before {
  content: "\E050";
}
.halflings-text-width:before {
  content: "\E051";
}
.halflings-align-left:before {
  content: "\E052";
}
.halflings-align-center:before {
  content: "\E053";
}
.halflings-align-right:before {
  content: "\E054";
}
.halflings-align-justify:before {
  content: "\E055";
}
.halflings-list:before {
  content: "\E056";
}
.halflings-indent-left:before {
  content: "\E057";
}
.halflings-indent-right:before {
  content: "\E058";
}
.halflings-facetime-video:before {
  content: "\E059";
}
.halflings-picture:before {
  content: "\E060";
}
.halflings-pencil:before {
  content: "\270F";
}
.halflings-map-marker:before {
  content: "\E062";
}
.halflings-adjust:before {
  content: "\E063";
}
.halflings-tint:before {
  content: "\E064";
}
.halflings-edit:before {
  content: "\E065";
}
.halflings-share:before {
  content: "\E066";
}
.halflings-check:before {
  content: "\E067";
}
.halflings-move:before {
  content: "\E068";
}
.halflings-step-backward:before {
  content: "\E069";
}
.halflings-fast-backward:before {
  content: "\E070";
}
.halflings-backward:before {
  content: "\E071";
}
.halflings-play:before {
  content: "\E072";
}
.halflings-pause:before {
  content: "\E073";
}
.halflings-stop:before {
  content: "\E074";
}
.halflings-forward:before {
  content: "\E075";
}
.halflings-fast-forward:before {
  content: "\E076";
}
.halflings-step-forward:before {
  content: "\E077";
}
.halflings-eject:before {
  content: "\E078";
}
.halflings-chevron-left:before {
  content: "\E079";
}
.halflings-chevron-right:before {
  content: "\E080";
}
.halflings-plus-sign:before {
  content: "\E081";
}
.halflings-minus-sign:before {
  content: "\E082";
}
.halflings-remove-sign:before {
  content: "\E083";
}
.halflings-ok-sign:before {
  content: "\E084";
}
.halflings-question-sign:before {
  content: "\E085";
}
.halflings-info-sign:before {
  content: "\E086";
}
.halflings-screenshot:before {
  content: "\E087";
}
.halflings-remove-circle:before {
  content: "\E088";
}
.halflings-ok-circle:before {
  content: "\E089";
}
.halflings-ban-circle:before {
  content: "\E090";
}
.halflings-arrow-left:before {
  content: "\E091";
}
.halflings-arrow-right:before {
  content: "\E092";
}
.halflings-arrow-up:before {
  content: "\E093";
}
.halflings-arrow-down:before {
  content: "\E094";
}
.halflings-share-alt:before {
  content: "\E095";
}
.halflings-resize-full:before {
  content: "\E096";
}
.halflings-resize-small:before {
  content: "\E097";
}
.halflings-plus:before {
  content: "\002B";
}
.halflings-minus:before {
  content: "\2212";
}
.halflings-asterisk:before {
  content: "\002A";
}
.halflings-exclamation-sign:before {
  content: "\E101";
}
.halflings-gift:before {
  content: "\E102";
}
.halflings-leaf:before {
  content: "\E103";
}
.halflings-glyph-fire:before {
  content: "\E104";
}
.halflings-eye-open:before {
  content: "\E105";
}
.halflings-eye-close:before {
  content: "\E106";
}
.halflings-warning-sign:before {
  content: "\E107";
}
.halflings-plane:before {
  content: "\E108";
}
.halflings-glyph-calendar:before {
  content: "\E109";
}
.halflings-random:before {
  content: "\E110";
}
.halflings-comments:before {
  content: "\E111";
}
.halflings-magnet:before {
  content: "\E112";
}
.halflings-chevron-up:before {
  content: "\E113";
}
.halflings-chevron-down:before {
  content: "\E114";
}
.halflings-retweet:before {
  content: "\E115";
}
.halflings-shopping-cart:before {
  content: "\E116";
}
.halflings-folder-close:before {
  content: "\E117";
}
.halflings-folder-open:before {
  content: "\E118";
}
.halflings-resize-vertical:before {
  content: "\E119";
}
.halflings-resize-horizontal:before {
  content: "\E120";
}
.halflings-hdd:before {
  content: "\E121";
}
.halflings-bullhorn:before {
  content: "\E122";
}
.halflings-glyph-bell:before {
  content: "\E123";
}
.halflings-certificate:before {
  content: "\E124";
}
.halflings-thumbs-up:before {
  content: "\E125";
}
.halflings-thumbs-down:before {
  content: "\E126";
}
.halflings-hand-right:before {
  content: "\E127";
}
.halflings-hand-left:before {
  content: "\E128";
}
.halflings-hand-top:before {
  content: "\E129";
}
.halflings-hand-down:before {
  content: "\E130";
}
.halflings-circle-arrow-right:before {
  content: "\E131";
}
.halflings-circle-arrow-left:before {
  content: "\E132";
}
.halflings-circle-arrow-top:before {
  content: "\E133";
}
.halflings-circle-arrow-down:before {
  content: "\E134";
}
.halflings-globe:before {
  content: "\E135";
}
.halflings-glyph-wrench:before {
  content: "\E136";
}
.halflings-tasks:before {
  content: "\E137";
}
.halflings-filter:before {
  content: "\E138";
}
.halflings-glyph-briefcase:before {
  content: "\E139";
}
.halflings-fullscreen:before {
  content: "\E140";
}
.halflings-dashboard:before {
  content: "\E141";
}
.halflings-glyph-paperclip:before {
  content: "\E142";
}
.halflings-heart-empty:before {
  content: "\E143";
}
.halflings-link:before {
  content: "\E144";
}
.halflings-phone:before {
  content: "\E145";
}
.halflings-glyph-pushpin:before {
  content: "\E146";
}
.halflings-euro:before {
  content: "\20AC";
}
.halflings-usd:before {
  content: "\E148";
}
.halflings-gbp:before {
  content: "\E149";
}
.halflings-sort:before {
  content: "\E150";
}
.halflings-sort-by-alphabet:before {
  content: "\E151";
}
.halflings-sort-by-alphabet-alt:before {
  content: "\E152";
}
.halflings-sort-by-order:before {
  content: "\E153";
}
.halflings-sort-by-order-alt:before {
  content: "\E154";
}
.halflings-sort-by-attributes:before {
  content: "\E155";
}
.halflings-sort-by-attributes-alt:before {
  content: "\E156";
}
.halflings-unchecked:before {
  content: "\E157";
}
.halflings-expand:before {
  content: "\E158";
}
.halflings-collapse:before {
  content: "\E159";
}
.halflings-collapse-top:before {
  content: "\E160";
}
.halflings-log-in:before {
  content: "\E161";
}
.halflings-flash:before {
  content: "\E162";
}
.halflings-log-out:before {
  content: "\E163";
}
.halflings-new-window:before {
  content: "\E164";
}
.halflings-record:before {
  content: "\E165";
}
.halflings-save:before {
  content: "\E166";
}
.halflings-open:before {
  content: "\E167";
}
.halflings-saved:before {
  content: "\E168";
}
.halflings-import:before {
  content: "\E169";
}
.halflings-export:before {
  content: "\E170";
}
.halflings-send:before {
  content: "\E171";
}
.halflings-floppy-disk:before {
  content: "\E172";
}
.halflings-floppy-saved:before {
  content: "\E173";
}
.halflings-floppy-remove:before {
  content: "\E174";
}
.halflings-floppy-save:before {
  content: "\E175";
}
.halflings-floppy-open:before {
  content: "\E176";
}
.halflings-credit-card:before {
  content: "\E177";
}
.halflings-transfer:before {
  content: "\E178";
}
.halflings-cutlery:before {
  content: "\E179";
}
.halflings-header:before {
  content: "\E180";
}
.halflings-compressed:before {
  content: "\E181";
}
.halflings-earphone:before {
  content: "\E182";
}
.halflings-phone-alt:before {
  content: "\E183";
}
.halflings-tower:before {
  content: "\E184";
}
.halflings-stats:before {
  content: "\E185";
}
.halflings-sd-video:before {
  content: "\E186";
}
.halflings-hd-video:before {
  content: "\E187";
}
.halflings-subtitles:before {
  content: "\E188";
}
.halflings-sound-stereo:before {
  content: "\E189";
}
.halflings-sound-dolby:before {
  content: "\E190";
}
.halflings-sound-5-1:before {
  content: "\E191";
}
.halflings-sound-6-1:before {
  content: "\E192";
}
.halflings-sound-7-1:before {
  content: "\E193";
}
.halflings-copyright-mark:before {
  content: "\E194";
}
.halflings-registration-mark:before {
  content: "\E195";
}
.halflings-cloud:before {
  content: "\2601";
}
.halflings-cloud-download:before {
  content: "\E197";
}
.halflings-cloud-upload:before {
  content: "\E198";
}
.halflings-tree-conifer:before {
  content: "\E199";
}
.halflings-tree-deciduous:before {
  content: "\E200";
}
.halflings-cd:before {
  content: "\E201";
}
.halflings-save-file:before {
  content: "\E202";
}
.halflings-open-file:before {
  content: "\E203";
}
.halflings-level-up:before {
  content: "\E204";
}
.halflings-copy:before {
  content: "\E205";
}
.halflings-paste:before {
  content: "\E206";
}
.halflings-door:before {
  content: "\1F6AA";
}
.halflings-key:before {
  content: "\1F511";
}
.halflings-alert:before {
  content: "\E209";
}
.halflings-equalizer:before {
  content: "\E210";
}
.halflings-king:before {
  content: "\E211";
}
.halflings-queen:before {
  content: "\E212";
}
.halflings-pawn:before {
  content: "\E213";
}
.halflings-bishop:before {
  content: "\E214";
}
.halflings-knight:before {
  content: "\E215";
}
.halflings-baby-formula:before {
  content: "\E216";
}
.halflings-tent:before {
  content: "\26FA";
}
.halflings-blackboard:before {
  content: "\E218";
}
.halflings-bed:before {
  content: "\E219";
}
.halflings-apple:before {
  content: "\F8FF";
}
.halflings-erase:before {
  content: "\E221";
}
.halflings-hourglass:before {
  content: "\231B";
}
.halflings-lamp:before {
  content: "\E223";
}
.halflings-duplicate:before {
  content: "\E224";
}
.halflings-piggy-bank:before {
  content: "\E225";
}
.halflings-scissors:before {
  content: "\E226";
}
.halflings-bitcoin:before {
  content: "\E227";
}
.halflings-yen:before {
  content: "\00A5";
}
.halflings-ruble:before {
  content: "\20BD";
}
.halflings-scale:before {
  content: "\E230";
}
.halflings-ice-lolly:before {
  content: "\E231";
}
.halflings-ice-lolly-tasted:before {
  content: "\E232";
}
.halflings-education:before {
  content: "\E233";
}
.halflings-option-horizontal:before {
  content: "\E234";
}
.halflings-option-vertical:before {
  content: "\E235";
}
.halflings-menu-hamburger:before {
  content: "\E236";
}
.halflings-modal-window:before {
  content: "\E237";
}
.halflings-oil:before {
  content: "\E238";
}
.halflings-grain:before {
  content: "\E239";
}
.halflings-sunglasses:before {
  content: "\E240";
}
.halflings-text-size:before {
  content: "\E241";
}
.halflings-text-color:before {
  content: "\E242";
}
.halflings-text-background:before {
  content: "\E243";
}
.halflings-object-align-top:before {
  content: "\E244";
}
.halflings-object-align-bottom:before {
  content: "\E245";
}
.halflings-object-align-horizontal:before {
  content: "\E246";
}
.halflings-object-align-left:before {
  content: "\E247";
}
.halflings-object-align-vertical:before {
  content: "\E248";
}
.halflings-object-align-right:before {
  content: "\E249";
}
.halflings-triangle-right:before {
  content: "\E250";
}
.halflings-triangle-left:before {
  content: "\E251";
}
.halflings-triangle-bottom:before {
  content: "\E252";
}
.halflings-triangle-top:before {
  content: "\E253";
}
.halflings-terminal:before {
  content: "\E254";
}
.halflings-superscript:before {
  content: "\E255";
}
.halflings-subscript:before {
  content: "\E256";
}
.halflings-menu-left:before {
  content: "\E257";
}
.halflings-menu-right:before {
  content: "\E258";
}
.halflings-menu-down:before {
  content: "\E259";
}
.halflings-menu-up:before {
  content: "\E260";
}
/* 
* 
* THIS IS A SMALL BONUS FOR ALL CURIOUS PEOPLE :) 
* Just add class .animated and .pulse, .rotateIn, .bounce, .swing or .tada to you HTML element with icons. You may find other great css animations here: http://coveloping.com/tools/css-animation-generator 
* 
*/
.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite;
}
@-webkit-keyframes pulse {
  0% {
    -webkit-transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.1);
  }
  100% {
    -webkit-transform: scale(1);
  }
}
@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
.pulse {
  -webkit-animation-name: pulse;
  animation-name: pulse;
}
@-webkit-keyframes rotateIn {
  0% {
    -webkit-transform-origin: center center;
    -webkit-transform: rotate(-200deg);
    opacity: 0;
  }
  100% {
    -webkit-transform-origin: center center;
    -webkit-transform: rotate(0);
    opacity: 1;
  }
}
@keyframes rotateIn {
  0% {
    transform-origin: center center;
    transform: rotate(-200deg);
    opacity: 0;
  }
  100% {
    transform-origin: center center;
    transform: rotate(0);
    opacity: 1;
  }
}
.rotateIn {
  -webkit-animation-name: rotateIn;
  animation-name: rotateIn;
}
@-webkit-keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    -webkit-transform: translateY(0);
  }
  40% {
    -webkit-transform: translateY(-30px);
  }
  60% {
    -webkit-transform: translateY(-15px);
  }
}
@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}
.bounce {
  -webkit-animation-name: bounce;
  animation-name: bounce;
}
@-webkit-keyframes swing {
  20%,
  40%,
  60%,
  80%,
  100% {
    -webkit-transform-origin: top center;
  }
  20% {
    -webkit-transform: rotate(15deg);
  }
  40% {
    -webkit-transform: rotate(-10deg);
  }
  60% {
    -webkit-transform: rotate(5deg);
  }
  80% {
    -webkit-transform: rotate(-5deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
  }
}
@keyframes swing {
  20% {
    transform: rotate(15deg);
  }
  40% {
    transform: rotate(-10deg);
  }
  60% {
    transform: rotate(5deg);
  }
  80% {
    transform: rotate(-5deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
.swing {
  -webkit-transform-origin: top center;
  transform-origin: top center;
  -webkit-animation-name: swing;
  animation-name: swing;
}
@-webkit-keyframes tada {
  0% {
    -webkit-transform: scale(1);
  }
  10%,
  20% {
    -webkit-transform: scale(0.9) rotate(-3deg);
  }
  30%,
  50%,
  70%,
  90% {
    -webkit-transform: scale(1.1) rotate(3deg);
  }
  40%,
  60%,
  80% {
    -webkit-transform: scale(1.1) rotate(-3deg);
  }
  100% {
    -webkit-transform: scale(1) rotate(0);
  }
}
@keyframes tada {
  0% {
    transform: scale(1);
  }
  10%,
  20% {
    transform: scale(0.9) rotate(-3deg);
  }
  30%,
  50%,
  70%,
  90% {
    transform: scale(1.1) rotate(3deg);
  }
  40%,
  60%,
  80% {
    transform: scale(1.1) rotate(-3deg);
  }
  100% {
    transform: scale(1) rotate(0);
  }
}
.tada {
  -webkit-animation-name: tada;
  animation-name: tada;
}
