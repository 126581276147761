.file-upload-container {
    background-color: #f5f5f5;
    display: table;
    width: 100%;
    height: 400px;
}

.file-upload-content {
    display: table-cell;
    vertical-align: middle;
}

.file-upload-modal-body {
    margin: 0;
    padding: 0;
}