.login-form {
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: block;
    width: 200px;
    height: 300px;
    text-align: center;
}

.login-button {
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 50px;
    width: 220px;
}