.context-menu {
    padding: 0 7px;
    margin: 50px -20px 0 -20px;
    background-color: rgb(78, 93, 108);
    color: #ccc;
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 1020;
}

.context-menu a {
    color: #fff;
}

.context-menu label {
    font-weight: normal;
    padding-left: 5px;
    margin-bottom: 0;
    cursor: pointer;
}

.context-menu .nav-pills > li > a {
    border-radius: 0;
}

.context-menu .nav-pills > li.active > a,
.context-menu .nav-pills > li.active > a:hover,
.context-menu .nav-pills > li.active > a:focus {
    background-color: #2b3e50;
}

.context-menu .nav > li > a:hover,
.context-menu .nav > li > a:focus {
    background-color: #2b3e50;
}

.context-menu .nav > li.disabled > a {
    color: #ccc;
}

.context-menu .nav > li.disabled > a:hover,
.context-menu .nav > li.disabled > a:focus {
    color: #ccc;
    text-decoration: none;
    cursor: not-allowed;
    background-color: transparent;
}